<template>
    <main class="main-page"  id="">
        <template v-if="showHeader">
            <section class="page-section mb-3" >
                <div class="container-fluid">
                    <div class="grid justify-content-between align-items-center">
                        <div  class="col " >
                            <div class=" flex gap-2 align-items-center " >
                                <Avatar size="large" class="" icon="pi pi-database" />
                                    <div class="text-2xl text-primary font-bold flex-1">
                                        FTP Log
                                    </div>
                                </div>
                            </div>
                            <div  class="col-12 md:col-3 " >
                            </div>
                            <div  class="col-12 md:col-5 lg:col-4 " >
                                <span class="p-input-icon-left w-full">
                                <i class="pi pi-search" />
                                <InputText  placeholder="Buscar" class="w-full" :value="searchText" @input="debounce(() => { searchText = $event.target.value })"  />
                                </span>
                            </div>
                        </div>
                    </div>
                </section>
            </template>
            <section class="page-section " >
                <div class="container-fluid">
                    <div class="grid ">
                        <div  class="col comp-grid" >
                            <div class="flex align-items-center justify-content-around">
                                <div v-if="searchText" :class="filterTagClass">
                                    Buscar
                                    <Chip class="font-medium px-2 py-1" removable @remove="clearSearch()">{{ searchText }}</Chip>
                                </div>
                            </div>
                            <div >
                                <template v-if="showBreadcrumbs && $route.query.tag && !isSubPage">
                                    <Breadcrumb :home="{icon: 'pi pi-home', to: '/data'}" :model="pageBreadCrumb" />
                                </template>
                                <!-- page records template -->
                                <div class="page-records"  >
                                    <DataTable :lazy="true"   :loading="loading"     :value="records" dataKey="id" @sort="onSort($event)" class="" :showGridlines="false" :rowHover="true" responsiveLayout="stack">
                                        <Column  field="created_date" header="Recibido" >
                                            <template #body="{data}">
                                                {{ data.created_date }}
                                            </template>
                                        </Column>
                                        <Column  field="filename" header="Archivo" >
                                            <template #body="{data}">
                                                {{ data.filename }}
                                            </template>
                                        </Column>
                                        <Column  field="file_size" header="Tamaño" >
                                            <template #body="{data}">
                                                {{ data.file_size }}
                                            </template>
                                        </Column>
                                        <Column  field="value_01" header="P01" >
                                            <template #body="{data}">
                                                {{ data.value_01 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_02" header="P02" >
                                            <template #body="{data}">
                                                {{ data.value_02 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_03" header="P03" >
                                            <template #body="{data}">
                                                {{ data.value_03 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_04" header="P04" >
                                            <template #body="{data}">
                                                {{ data.value_04 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_05" header="P05" >
                                            <template #body="{data}">
                                                {{ data.value_05 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_06" header="P06" >
                                            <template #body="{data}">
                                                {{ data.value_06 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_07" header="P07" >
                                            <template #body="{data}">
                                                {{ data.value_07 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_08" header="P08" >
                                            <template #body="{data}">
                                                {{ data.value_08 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_09" header="P09" >
                                            <template #body="{data}">
                                                {{ data.value_09 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_10" header="P10" >
                                            <template #body="{data}">
                                                {{ data.value_10 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_11" header="P11" >
                                            <template #body="{data}">
                                                {{ data.value_11 }}
                                            </template>
                                        </Column>
                                        <Column  field="value_12" header="P12" >
                                            <template #body="{data}">
                                                {{ data.value_12 }}
                                            </template>
                                        </Column>
                                        <Column  headerStyle="width: 2rem" headerClass="text-center">
                                            <template #body="{data}">
                                                <div class="flex justify-content-end">
                                                    <SplitButton dropdownIcon="pi pi-bars" class="p-button dropdown-only p-button-text p-button-plain" :model="getActionMenuModel(data)">
                                                        <i></i>
                                                    </SplitButton>
                                                </div>
                                            </template>
                                        </Column>
                                    </DataTable>
                                </div>
                                <!-- page loading indicator -->
                                <template v-if="loading">
                                </template>
                                <!-- end of page loading indicator-->
                                <!-- Empty record -->
                                <template v-if="pageReady && !records.length">
                                    <div class="p-3 my-3 text-500 text-lg font-medium text-center">
                                        ningún record fue encontrado
                                    </div>
                                </template>
                                <!-- end of empty record-->
                                <!-- pagination component-->
                                <template v-if="showFooter && pageReady">
                                    <div class="flex justify-content-between align-items-center">
                                        <div class="flex justify-content-center flex-grow-0">
                                            <div class="m-2" v-if="exportButton && records.length">
                                                <Button @click="(event)=> $refs.exportMenu.toggle(event)" label="Exportar"  title="Exportar" icon="pi pi-print" />
                                                <Menu ref="exportMenu" popup :model="pageExportFormats" />
                                            </div>
                                        </div>
                                        <div v-if="paginate && totalPages > 1" class="flex-grow-1">
                                            <Paginator class="border-none bg-transparent py-3" :first="recordsPosition - 1" @page="(event)=>{pagination.page = event.page + 1}" :rows="pagination.limit" :totalRecords="totalRecords">
                                                <template #start>
                                                    <span class="text-sm text-gray-500 px-2">
                                                    Archivos <b>{{ recordsPosition }} de {{ totalRecords }}</b>
                                                    </span>
                                                </template>
                                                <template #end>
                                                </template>
                                            </Paginator>
                                        </div>
                                    </div>
                                </template>
                                <!-- end of pagination component-->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
</template>
<script setup>
	import {   toRefs, onMounted } from 'vue';
	import { usePageStore } from 'src/store/page';
	import { useApp } from 'src/composables/app.js';
	import { useAuth } from 'src/composables/auth';
	import { useListPage } from 'src/composables/listpage.js';
	
	const props = defineProps({
		primaryKey : {
			type : String,
			default : 'id',
		},
		pageStoreKey: {
			type: String,
			default: 'DATA',
		},
		pageName: {
			type: String,
			default : 'data',
		},
		routeName: {
			type: String,
			default: 'datalist',
		},
		apiPath: {
			type: String,
			default: 'data/index',
		},
		paginate: {
			type: Boolean,
			default: true,
		},
		showHeader: {
			type: Boolean,
			default: true,
		},
		showFooter: {
			type: Boolean,
			default: true,
		},
		showBreadcrumbs: {
			type: Boolean,
			default: true,
		},
		exportButton: {
			type: Boolean,
			default: true,
		},
		importButton: {
			type: Boolean,
			default: false,
		},
		multiCheckbox: {
			type: Boolean,
			default: false,
		},
		emptyRecordMsg: {
			type: String,
			default: "ningún record fue encontrado",
		},
		titleBeforeDelete: {
			type: String,
			default: "Eliminar el registro",
		},
		msgBeforeDelete: {
			type: String,
			default: "¿Seguro que quieres borrar este registro?",
		},
		msgAfterDelete: {
			type: String,
			default: "Grabar eliminado con éxito",
		},
		page: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 10,
		},
		mergeRecords: { // for infinite loading
			type: Boolean,
			default: false,
		},
		search: {
			type: String,
			default: '',
		},
		fieldName: null,
		fieldValue: null,
		sortBy: {
			type: String,
			default: '',
		},
		sortType: {
			type: String,
			default: 'desc', //desc or asc
		},
		isSubPage: {
			type: Boolean,
			default: false,
		},
		filterTagClass: {
			type: String,
			default: 'surface-card p-2 text-500 flex-grow-1 text-center m-1 mb-3 flex-grow-1 text-center',
		}
	});
	
	const app = useApp();
	const auth = useAuth();
	
	const defaultStoreState = {
		filters: {
		},
		pagination: {
			page: props.page,
			limit: props.limit,
			sortBy: props.sortBy,
			sortType: props.sortType
		},
		searchText: props.search,
		primaryKey: props.primaryKey
	}
	const store = usePageStore(props.pageStoreKey,  defaultStoreState);
	
	// page hooks where logics resides
	const page = useListPage({ store, props });
	
	const {records, filters,  totalRecords,  selectedItems,  pagination,} = toRefs(store.state);
	const { pageReady, loading, searchText, } = toRefs(page.state);
	
	const {  pageBreadCrumb,   totalPages, recordsPosition, } = page.computedProps;
	
	const { load,    exportPage, debounce, clearSearch, onSort,  deleteItem,       } = page.methods;
	
	const pageExportFormats =  [
		{
			label: 'Pdf',
			icon: 'pi pi-file-pdf text-pink-500',
			command: () => { exportPage('pdf') }
		},
		{
			label: 'Excel',
			icon: 'pi pi-file-excel text-green-500',
			command: () => { exportPage('excel') }
		},
		{
			label: 'Csv',
			icon: 'pi pi-table text-green-200',
			command: () => { exportPage('csv') }
		}
	];
	function getActionMenuModel(data){
		return [
		{
			label: "View",
			to: `/data/view/${data.id}`,
			icon: "pi pi-eye",
			visible: auth.canView('data/view')
		}
	]
	}
	
	onMounted(()=>{ 
		const pageTitle = "Data";
		app.setPageTitle(props.routeName, pageTitle);
	});
</script>
<style scoped>
</style>
